<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>

        <div class="container-fluid mt--6">
            <div class="card mb-4 mt--7">
                <div class="card-header">
                    <div class="row">
                        <div class="col-6">
                            <h2 class="mb-0">{{ tt('sap_import_table') }}</h2>
                        </div>
                        <div class="col-6 row d-flex justify-content-end pr-0">
                            <div class="col-3 text-right">
                                <base-button size="md" type="default" @click="importButton">{{ tt('import') }}</base-button>
                            </div>
                            <!-- <div class="col-6 btn-group" role="group">
                                <el-select v-model="file.template_type" style="width: 100%;" clearable placeholder="Pick the excel template" @change="changeTemplateType">
                                    <el-option :value="temp" v-for="temp in template_kind" :key="temp" :label="temp"></el-option>
                                </el-select>
                            </div>
                            <div class="col-1">
                                <a :href="storageUrl+'sap_import/sap_import_templates/'+file.template_type" class="fas fa-download btn btn-md btn-default" v-if="!btnDownload.disabled"></a>
                                <base-button class=" fas fa-download btn btn-md btn-default" type="dark" disabled v-else></base-button>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div>
                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoadTable">
                        <el-table-column :label="tt('date_import')" :prop="tt('date_import')" min-width="200" sortable>
                            <template v-slot="{row}">
                                {{row.date_import_file}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('import_to')" :prop="tt('import_to')" min-width="200" sortable>
                            <template v-slot="{row}">
                                {{tt(row.import_to)}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('imported_by')" :prop="tt('imported_by')" min-width="200" sortable>
                            <template v-slot="{row}">
                                {{row.created_by}}
                            </template>
                        </el-table-column>
                        <el-table-column label="file" :prop="tt('imported_file')" min-width="150" sortable>
                            <template v-slot="{row}">
                                <el-tooltip class="item" effect="dark" :content="row.filename" placement="top-start" v-if="!!row.filename">
                                    <a :href="storageUrl+'sap_import/'+row.filename">
                                        <span class="text-success"><i class="fa fa-file-excel"></i></span>
                                    </a>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('status')" :prop="tt('status')" min-width="160" sortable>
                            <template v-slot="{row}">
                                <span class="badge badge-primary" v-if="resultStatus(row.status) == 0">Processing</span>
                                <span class="badge badge-danger" v-if="resultStatus(row.status) == 1">Failed</span>
                                <span class="badge badge-success" v-if="resultStatus(row.status) == 2">Success</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template slot-scope="scope">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item :command="{action:'log', data:scope.$index}">{{ tt('detail_import_log') }}</el-dropdown-item>
                                        <!-- <el-dropdown-item :command="{action:'delete', data:scope.$index}">{{ tt('delete') }}</el-dropdown-item> -->
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                    <skeleton-loading v-else/>
                </div>
                <div class="card-footer pb-0 ">   
                    <span class="float-left">
                        <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                    </span>                             
                    <span class="float-right">
                        {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                    </span>
                </div>
            </div>
        </div>

        <!-- MODAL INPUT EXCEL FILE AND START IMPORT PROCESS -->
        <validation-observer v-slot="{invalid}">
            <modal :show.sync="formImport.show" size="xl">
                <template slot="header">
                    <h5 class="modal-title">{{formImport.title}}</h5>
                </template>
                <div class="mb-3">
                    <span style="font-size: 15px;">
                        {{ formImport.inputFile.body ? tt(formImport.inputFile.text) : formImport.startImport.body ? tt(formImport.startImport.text) : ''}}
                    </span>
                </div>
   
                <!-- INPUT EXCEL FILE -->
                <div class="row" v-if="formImport.inputFile.body">
                    <div class="col-12 row">
                        <div class="col-6 text-left">
                            <label class="form-control-label">{{ tt('import_to') }} <span class="text-danger">*</span></label>
                            <el-select v-model="formImport.inputFile.import_to" style="width: 100%;" placeholder="Pick the column">
                                <el-option :value="colImport" v-for="colImport in formImport.inputFile.to_column" :key="colImport" :label="tt(colImport)"></el-option>
                            </el-select>
                        </div>
                        <div class="col-6 text-left">
                            <label class="form-control-label">{{ tt('input_excel') }} <span class="text-danger">*</span></label>
                            <div class="file-input-container">
                                <input type="file" class="form-control" @change="changeImport" rules="required" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">    
                            </div>
                            <!-- <span style="font-size: 9pt;">{{ formImport.inputFile.filename ? formImport.inputFile.filename+' file has been inputted' : ''}}</span> -->
                        </div>
                    </div>
                    <div class="col-12 row d-flex justify-content-start pt-2">
                        <div class="col-12">
                            <label class="fw-bold" style="font-size: 11px">
                                <a href="#" @click="downloadFiles">{{ tt('download_excel_template_sap_import') }}</a><br>
                                <a ref="fileKesatu" style="display: none;" :href="storageUrl+'sap_import/sap_import_templates/'+'TOMS Material Master Import Template.xlsx'" target="_blank"></a>
                                <a ref="fileKedua" style="display: none;" :href="storageUrl+'sap_import/sap_import_templates/'+'TOMS Material Cataloging Import Template.xlsx'" target="_blank"></a>
                                <a ref="fileKetiga" style="display: none;" :href="storageUrl+'sap_import/sap_import_templates/'+'TOMS Class Import Template.xlsx'" target="_blank"></a>
                                <a ref="fileKeempat" style="display: none;" :href="storageUrl+'sap_import/sap_import_templates/'+'TOMS Equipment Import Template.xlsx'" target="_blank"></a>
                                <a ref="fileKelima" style="display: none;" :href="storageUrl+'sap_import/sap_import_templates/'+'TOMS Item Name Import Template.xlsx'" target="_blank"></a>
                                <a ref="fileKeenam" style="display: none;" :href="storageUrl+'sap_import/sap_import_templates/'+'TOMS Manufacture Import Template.xlsx'" target="_blank"></a>
                                <a ref="fileKetujuh" style="display: none;" :href="storageUrl+'sap_import/sap_import_templates/'+'TOMS Panduan Template Import.xlsx'" target="_blank"></a>
                            </label>
                        </div>
                    </div>
                </div>

                <!-- START IMPORT PROCESS -->
                <div v-else-if="formImport.startImport.body">
                    <div class="row mb-3">
                        <div class="col-12 row mb-1">
                            <div class="col-2">
                                <span class="form-control-label" style="font-size: 13px;">Process Date</span>
                            </div>
                            <div style="max-width: 1.5%; flex: 0 0 1.5%;">
                                <span class="form-control-label" style="font-size: 13px;">:</span>
                            </div>
                            <div class="col-6">
                                <span class="form-control-label" style="font-size: 13px;">{{ returnImport.tanggalProses ? returnImport.tanggalProses : '-' }}</span>
                            </div>
                        </div>
                        <div class="col-12 row mb-1">
                            <div class="col-2">
                                <span class="form-control-label" style="font-size: 13px;">Processed</span>
                            </div>
                            <div style="max-width: 1.5%; flex: 0 0 1.5%;">
                                <span class="form-control-label" style="font-size: 13px;">:</span>
                            </div>
                            <div class="col-6">
                                <span class="form-control-label" style="font-size: 13px;">{{ returnImport.jumlahLine ? returnImport.jumlahLine + tt('baris_data') : '-' }}</span>
                            </div>
                        </div>
                        <div class="col-12 row mb-1">
                            <div class="col-2">
                                <span class="form-control-label" style="font-size: 13px;">Success</span>
                            </div>
                            <div style="max-width: 1.5%; flex: 0 0 1.5%;">
                                <span class="form-control-label" style="font-size: 13px;">:</span>
                            </div>
                            <div class="col-6">
                                <span :class="['form-control-label', {'text-success': returnImport.jumlahSukses}]" style="font-size: 13px;">{{ returnImport.jumlahSukses ? returnImport.jumlahSukses + tt('baris_data') : '-' }}</span>
                            </div>
                        </div>
                        <div class="col-12 row mb-1">
                            <div class="col-2">
                                <span class="form-control-label" style="font-size: 13px;">Failed</span>
                            </div>
                            <div style="max-width: 1.5%; flex: 0 0 1.5%;">
                                <span class="form-control-label" style="font-size: 13px;">:</span>
                            </div>
                            <div class="col-6">
                                <span :class="['form-control-label', {'text-danger': returnImport.jumlahGagal}]" style="font-size: 13px;">{{ returnImport.jumlahGagal ? returnImport.jumlahGagal + tt('baris_data') : '-' }}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mt-4 mb-3">
                        <h4>Log History</h4>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="returnImport.tableDetail">
                            <el-table-column :label="tt('sheet')" :prop="tt('sheet')" min-width="120" sortable>
                                <template v-slot="{row}">
                                    {{ row.sheet }}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('row')" :prop="tt('row')" min-width="70" sortable>
                                <template v-slot="{row}">
                                    {{row.row}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('status')" :prop="tt('status')" min-width="80" sortable>
                                <template v-slot="{row}">
                                    <span class="badge badge-success" v-if="row.status == 'success'">Success</span>
                                    <span class="badge badge-danger" v-if="row.status == 'error'">Failed</span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('note')" :prop="tt('note')" min-width="300" sortable>
                                <template v-slot="{row}">
                                    {{ row.message }}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('values')" :prop="tt('values')" min-width="200" sortable>
                                <template v-slot="{row}">
                                    {{row.value}}
                                </template>
                            </el-table-column>
                            <el-table-column label="ref" :prop="tt('row')" sortable>
                                <template v-slot="{row}">
                                    {{row.materialNumber}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- <div class="progress">
                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div> -->
                </div>

                <template slot="footer">
                    <!-- BUTTON BACK & CLOSE -->
                    <base-button type="secondary" v-if="formImport.inputFile.body" @click="backCloseModal">{{ tt('close') }}</base-button>
                    <base-button type="secondary" v-else-if="formImport.startImport.body && formImport.startImport.backToInputFile" @click="backToInputFile">{{ tt('back') }}</base-button>
                    <base-button type="secondary" v-else-if="formImport.startImport.body && formImport.startImport.btnCloseAfterImport" @click="btnCloseAfterImport">{{ tt('close') }}</base-button>

                    <!-- BUTTON NEXT & START -->
                    <base-button type="primary" v-if="formImport.inputFile.body" :disabled="invalid || formImport.inputFile.excel == '' || formImport.inputFile.import_to ==''" @click="inputFileRunning" >{{ tt('next') }}</base-button>
                    <base-button type="primary" v-else-if="formImport.startImport.body && formImport.startImport.btnStartImport" :disabled="formImport.startImport.btnStart.onLoading" @click="startImportRunning" >
                        <span v-if="formImport.startImport.btnStart.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt('please_wait') }}</span>
                        <span v-else>
                            <span>{{ tt('start') }}</span>
                        </span>
                    </base-button>
                </template>
            </modal>
        </validation-observer>

        <!-- MODAL DETAIL IMPORT -->
        <validation-observer>
            <modal :show.sync="formDetailImport.show" size="lg">
                <template slot="header">
                    <h5 class="modal-title">{{formDetailImport.title}}</h5>
                </template>
                
                <!-- DETAIL LOG -->
                <div v-if="formDetailImport.detailImportLog.body">
                    <div class="row mb-4">
                        <div class="col-12 mb-3" style="font-size: 12px;">
                            <span class="badge badge-primary mr-2">Total Data</span>
                            <span class="badge badge-success mr-2">Total Success</span>
                            <span class="badge badge-warning">Total Fail</span>
                        </div>

                        <div class="col-12 row" v-for="(val, key) in decodeStatus(formDetailImport.detailImportLog.status)" :key="key" style="display: flex; align-items: center;">
                            <div class="col-3">
                                <span class="form-control-label" style="font-size: 12px;">{{ key }}</span>
                            </div>
                            <div style="font-size: 12px;">
                                <span class="form-control-label">:</span>
                            </div>
                            <div class="col-6" style="font-size: 15px;">
                                <span class="badge badge-primary mr-2"><b>{{ val['totalData'] }}</b></span>
                                <span class="badge badge-success mr-2"><b>{{ val['totalSuccess'] }}</b></span>
                                <span class="badge badge-warning"><b>{{ val['totalFail'] }}</b></span>
                            </div>
                        </div>
                    </div>

                    <el-table height="400px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="formDetailImport.detailImportLog.log">
                        <el-table-column label="Key" prop="values" min-width="100" sortable>
                            <template v-slot="{row}">
                                {{ row.key_import }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('row')" prop="values" min-width="80" sortable>
                            <template v-slot="{row}">
                                {{ row.row }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('message')" prop="values" min-width="160" sortable>
                            <template v-slot="{row}">
                                {{ row.message }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Date" prop="values" min-width="160" sortable>
                            <template v-slot="{row}">
                                {{ row.created_at }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <template slot="footer">
                    <!-- BUTTON BACK & CLOSE -->
                    <base-button type="secondary" v-if="formDetailImport.detailImportLog.body" @click="btnCloseDetailLog">{{ tt('close') }}</base-button>
                </template>
            </modal>
        </validation-observer>

    </div>
</template>
<script>
    import {mapState} from 'vuex';
    import baseApiUrl from '@/configs/config';
    import Api from '@/helpers/api';
	import config from '@/configs/config';
    import SapImport from '@/services/report/sapimport.service.js';
 
    export default {
        
        data() {
            return {
                storageUrl: config.storageUrl,
                token: localStorage.token,
                apiUrl: baseApiUrl.apiUrl,
                loadTimeout: null,
                onLoadTable: false,
                file: {
                    template_type: '',
                },
                template_kind: [
                    'Material Master SAP Import Template.xlsx',
                    'Material Cataloguing SAP Import Template.xlsx',
                ],
                btnDownload: {
                    disabled: true
                },
                table: {
                    search: (this.$route.query.search) ? this.$route.query.search : '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    }
                },
                formImport: {
                    title: this.tt('form_import'),
                    show: false,
                    inputFile: {
                        body: true,
                        text: 'inputFile_text',
                        table: '',
                        excel: '',
                        filename: '',
                        import_to: '',
                        to_column: [
                            'material_master',
                            'material_cataloguing',
                            'item_name',
                            'class',
                            'manufacture',
                            'equipment',
                        ],
                    },
                    startImport: {
                        body: false,
                        text: 'startImport_text',
                        btnStartImport: true,
                        backToInputFile: true,
                        btnCloseAfterImport: false,
                        btnStart: {
                            onLoading: false,
                        },
                        // afterImport: false,
                    },
                },
                formDetailImport: {
                    title: this.tt('detail_data_import'),
                    show: false,
                    detailImportLog: {
                        body: false,
                        text: 'detailImportLog_text',
                        tanggalProses: '',
                        log: [],
                        status: '{}',
                    },
                },
                returnImport: {
                    jumlahLine: '',
                    jumlahGagal: '',
                    jumlahSukses: '',
                    tanggalProses: '',
                    tableDetail: [],
                    visibleTableDetail: [],
                    limitValue: 100,
                    isLoading: false,
                },
                errorMessage: null,   
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted(){
            this.get()
        },
        methods: {
            get() {
                let context = this;
                Api(context, SapImport.index(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response){
                    context.table.total = response.data.data.data.total
                    context.table.data  = response.data.data.data.data
                    context.table.page  = response.data.data.data
                    this.onLoadTable = false

                }).onError(function(error){
                    if (error.response.status == 404) {
                        context.table.total = 0
                        context.table.data  = []
                        this.onLoadTable = false
                    }
                })
                .call()
            },
            importButton() {
                this.formImport.inputFile.excel = '' 
                this.formImport.inputFile.import_to = ''
                this.formImport.inputFile.filename = ''
                this.errorMessage = false
                this.formImport.title = this.tt('form_import')
                this.formImport.show = true
                this.formImport.inputFile.body = true

                // Recreate the new file input
                const input = document.createElement('input')
                Object.assign(input,{type:'file', className: 'form-control', rules:'required'})
                input.addEventListener('change', this.changeImport)

                // replace the existing file input with the new one
                const fileInputContainer = document.querySelector('.file-input-container')
                if (fileInputContainer) {
                    fileInputContainer.innerHTML = ''
                    fileInputContainer.appendChild(input);
                }
            },
            inputFileRunning() {
                this.formImport.inputFile.body = false
                this.formImport.startImport.body = true

                this.formImport.startImport.btnCloseAfterImport = false
                this.formImport.startImport.btnStartImport = true
                this.formImport.startImport.backToInputFile = true
                // this.formImport.startImport.afterImport = false

                this.returnImport.tanggalProses = ''
                this.returnImport.jumlahLine = ''
                this.returnImport.jumlahGagal = ''
                this.returnImport.jumlahSukses = ''
                this.returnImport.tableDetail = []
            },  
            startImportRunning() {
                this.formImport.startImport.btnStart.onLoading = true

                let context = this;
                let newSequence = context.table.data.length
                let formData = new FormData()
                
                formData.append('table', context.formImport.inputFile.table)
                formData.append('excel', context.formImport.inputFile.excel)
                formData.append('import_to', context.formImport.inputFile.import_to)

                Api(context, SapImport.import_table(formData)).onSuccess(function(response) {
                    if (response.data.data.sap_import.import_to == 'material_cataloguing') {
                        const totalDataProcessedSum = Object.values(response.data.data.allCalculation).reduce(
                                                        (sum, keyObject) => sum + keyObject.totalDataProcessed,
                                                        0
                                                    )
                        const totalSuccessSum       = Object.values(response.data.data.allCalculation).reduce(
                                                        (sum, keyObject) => sum + keyObject.totalSuccess,
                                                        0
                                                    )
                        const totalErrorSum         = Object.values(response.data.data.allCalculation).reduce(
                                                        (sum, keyObject) => sum + keyObject.totalError,
                                                        0
                                                    )

                        context.returnImport.tanggalProses = Object.values(response.data.data.allCalculation)[0].tanggalProses
                        context.returnImport.jumlahLine    = totalDataProcessedSum
                        context.returnImport.jumlahSukses  = totalSuccessSum
                        context.returnImport.jumlahGagal   = totalErrorSum   

                    } else if (response.data.data.sap_import.import_to == 'material_master' || 
                            response.data.data.sap_import.import_to == 'item_name' ||
                            response.data.data.sap_import.import_to == 'class' || 
                            response.data.data.sap_import.import_to == 'manufacture' || 
                            response.data.data.sap_import.import_to == 'equipment') {

                        context.returnImport.tanggalProses = response.data.data.allCalculation.tanggalProses
                        context.returnImport.jumlahLine    = response.data.data.allCalculation.totalDataProcessed
                        context.returnImport.jumlahGagal   = response.data.data.allCalculation.totalError
                        context.returnImport.jumlahSukses  = response.data.data.allCalculation.totalSuccess
                    }

                    // tambah array baru di context.table.data 
                    if (response.data.data.fail.length === 0 || Object.values(response.data.data.fail).flat().length === 0) {
                        const newData  = [response.data.data.sap_import]
                        const newArray = [...context.table.data.slice(0, newSequence), ...newData, ...context.table.data.slice(newSequence)]
                        context.table.data = newArray   
                    }

                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });

                    // context.formImport.startImport.afterImport = true
                }).onError(function(error){
                    if (error.response.status == 404) {
                        if (error.response.data.data && error.response.data.data.sap_import) {
                            if (error.response.data.data.sap_import.import_to == 'material_cataloguing') {
                                const totalDataProcessedSum = Object.values(error.response.data.data.allCalculation).reduce(
                                                                (sum, keyObject) => sum + keyObject.totalDataProcessed,
                                                                0
                                                            )
                                const totalSuccessSum       = Object.values(error.response.data.data.allCalculation).reduce(
                                                                (sum, keyObject) => sum + keyObject.totalSuccess,
                                                                0
                                                            )
                                const totalErrorSum         = Object.values(error.response.data.data.allCalculation).reduce(
                                                                (sum, keyObject) => sum + keyObject.totalError,
                                                                0
                                                            )
                                const combinedFailValues    = Object.values(error.response.data.data.fail).flat() 

                                context.returnImport.tanggalProses = Object.values(error.response.data.data.allCalculation)[0].tanggalProses
                                context.returnImport.jumlahLine    = totalDataProcessedSum
                                context.returnImport.jumlahSukses  = totalSuccessSum
                                context.returnImport.jumlahGagal   = totalErrorSum 
                                context.returnImport.tableDetail   = combinedFailValues.sort((a,b) => a.sheet - b.sheet)
                                context.updateVisibleData()

                            } else if(error.response.data.data.sap_import.import_to == 'material_master' || 
                                    error.response.data.data.sap_import.import_to == 'item_name' || 
                                    error.response.data.data.sap_import.import_to == 'class' || 
                                    error.response.data.data.sap_import.import_to == 'manufacture' || 
                                    error.response.data.data.sap_import.import_to == 'equipment') {

                                context.returnImport.tanggalProses = error.response.data.data.allCalculation.tanggalProses
                                context.returnImport.jumlahLine    = error.response.data.data.allCalculation.totalDataProcessed
                                context.returnImport.jumlahGagal   = error.response.data.data.allCalculation.totalError
                                context.returnImport.jumlahSukses  = error.response.data.data.allCalculation.totalSuccess
                                context.returnImport.tableDetail   = error.response.data.data.fail
                            }
                        }

                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        })
                    }
                }).onFinish(function(){
                    context.formImport.startImport.btnStart.onLoading = false
                    context.formImport.startImport.btnStartImport = false
                    context.formImport.startImport.backToInputFile = false
                    context.formImport.startImport.btnCloseAfterImport = true
                }).call()           
            },
            updateVisibleData() {
                this.returnImport.visibleTableDetail = this.returnImport.tableDetail.slice(0, this.returnImport.visibleTableDetail.length + this.returnImport.limitValue)
            },
            handleDetailScroll() {
                // let refTableDetail = this.$ref.tableDetail
                // let bottomDistance = refTableDetail.scrollHeight - (refTableDetail.scrollTop + refTableDetail.clientHeight)

                // if (!this.isLoading && bottomDistance < 50) {
                //     this.isLoading = true
                //     setTimeout(() => {
                //         this.updateVisibleData()
                //         this.isLoading = false

                //     }, 500)
                // }
            },
            backCloseModal() {
                this.formImport.show = false
                this.formImport.inputFile.body = false
            }, 
            backToInputFile(){
                this.formImport.inputFile.body = true
                this.formImport.startImport.body = false
            }, 
            btnCloseAfterImport() {
                this.formImport.show = false
                this.formImport.startImport.body = false
                this.formImport.startImport.btnCloseAfterImport = false
                this.formImport.startImport.btnStartImport = true
                this.formImport.startImport.backToInputFile = true
            },
            changeImport(event) {
                let excelFileName = event.target.files[0].name

                this.formImport.inputFile.excel    = event.target.files[0]
                //store filename once the file is inputted
                this.formImport.inputFile.filename = excelFileName
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'log' :
                        this.detailLog(command.data)
                        break;
                    case 'delete' :
                        this.delete(command.data)
                        break; 
                    default:
                        break;
                }
            },
            detailLog(key) {
                this.errorMessage = false
                this.formDetailImport.title = this.tt('detail_data_import')
                this.formDetailImport.show = true
                this.formDetailImport.detailImportLog.body = true
                this.formDetailImport.detailImportLog.status = '{}'
                this.formDetailImport.detailImportLog.log = []

                let context= this
                const detailLog = context.table.data[key]
                
                Api(context, SapImport.detail_log(detailLog.code)).onSuccess(function(response) {
                    context.formDetailImport.detailImportLog.status = response.data.data.status
                    context.formDetailImport.detailImportLog.log = response.data.data.sap_import_log
                }).onError(function(error) {
                    if (error.response.status == 404) {
                        context.formDetailImport.detailImportLog.status = '{}'
                        context.formDetailImport.detailImportLog.log = []
                    }
                }).call()
            },
            btnCloseDetailLog() {
                this.formDetailImport.show = false
                this.formDetailImport.detailImportLog.body = false
                this.formDetailImport.detailImportLog.tanggalProses = ''
                this.formDetailImport.detailImportLog.log = ''
            },
            delete(key) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this
                    const importArray = context.table.data[key]
                    if (result.value) {
                        Api(context, SapImport.delete(importArray.code)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            })

                            context.table.data.splice(key, 1)
                            
                        }).call()
                    }
                })
            },
            changeTemplateType() {
                if (this.file.template_type == '') {
                    this.btnDownload.disabled = true
                } else {
                    this.btnDownload.disabled = false
                }
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            decodeStatus(row) {
                return JSON.parse(row)          
            },
            resultStatus(row) {
                var status       = JSON.parse(row);
                var result       = Object.values(status)
                var totalData    = 0;
                var totalSuccess = 0;
                var totalFail    = 0;

                result.forEach((v) => {
                    totalData += v.totalData
                    totalSuccess += v.totalSuccess
                    totalFail += v.totalFail
                })

                var resultStatus = 0;
                if (totalData > (totalSuccess + totalFail)) {
                    resultStatus = 0;
                } else if(totalFail > 0) {
                    resultStatus = 1;
                } else {
                    resultStatus = 2;
                }

                return resultStatus;
            },
            downloadFiles() {
                this.$refs.fileKesatu.click();
                this.$refs.fileKedua.click();
                this.$refs.fileKetiga.click();
                this.$refs.fileKeempat.click();
                this.$refs.fileKelima.click();
                this.$refs.fileKeenam.click();
                this.$refs.fileKetujuh.click();
            },
        }
    }

</script>

<style>
	.el-tooltip__popper {
		max-width: 250px !important;
		word-break: break-word;
	}
    .table-log-history .el-table__body td {
        font-size: 12px;
    }
</style>