import Service from '@/helpers/service'

export default {

    index(page, params) {
        return Service().get('sap-import?page=' + page, {params});
    },
    detail_log(code) {
        return Service().get('sap-import/detail-log/' + code);
    },
    delete(code) {
        return Service().post('sap-import/delete/' + code);
    },
    import_table(params) {
        return Service().post('sap-import-process', params);
    },
}